/*
 * @Author: lbh
 * @Date: 2021-04-21 11:08:48
 * @LastEditors: lbh
 * @LastEditTime: 2022-07-21 12:14:10
 * @Description: file content
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import scroll from '@/js/utils/scroll';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './store/icon-ele/iconfont.css';
import './assets/font/font.css';
import VueGoogleMaps from 'xd-vue-googlemaps'; // 谷歌地圖
Vue.config.productionTip = false;

import All_Component from './components/components_index.js';

import i18n from './js/utils/i18n';

Vue.use(ElementUI);
Vue.use(All_Component);
Vue.use(VueGoogleMaps, {
  load: {
    apiKey: 'AIzaSyAecDoBrwpqZgDU7GbNelimZA2RS3JrDRg',
    libraries: ['places'],
    useBetaRenderer: false,
    // true代表中国区,false代表全球区
    // loadCn: window.localStorage.getItem("lang") == "zh-CN",
    callBack: function () {
      // 回调
    },
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  created() {
    if (location.host.includes('ricepon.com')) {
      let $favicon = document.querySelector('link[rel="icon"]');
      $favicon.href = '/ricepon-main/logo.png';
    }
    // 頁面滾動監聽
    new scroll();
  },
}).$mount('#app');
